import React from 'react'

import { useUspVisibility } from 'hooks'

const CaliforniaNoticesLink = ({ style, forceVisible = false }) => {
  const { isVisible } = useUspVisibility({ forceVisible })

  if (!isVisible && !forceVisible) {
    return null
  }
  return (
    <a href='https://www.barstoolsports.com/privacy-policy#ccpa' style={{ ...style, cursor: 'pointer' }}>
      California Notices
    </a>
  )
}

export default CaliforniaNoticesLink
