import React from 'react'
import Link from 'next/link'
import { getStore, isMobile } from 'utils'
import { NavigationStore } from 'stores/navigation'

const NavLink = ({ item }) => {
  const navigationStore = getStore(NavigationStore)
  const lowerCaseTitle = item.title.toLowerCase()

  let link = (
    <Link href={item.href}>
      <a
        itemProp='url'
        className={`siteNavigation__link ${item.primary ? 'siteNavigation__link--primary' : ''}`}
        onMouseEnter={() => {
          if (item.hasDropdown) {
            navigationStore.open(lowerCaseTitle)
          }
        }}
      >
        {item.title === 'More' ? '+' : item.title}
      </a>
    </Link>
  )
  if (item.external) {
    link = (
      <a
        itemProp='url'
        href={item.href}
        target='_blank'
        rel='noopener noreferrer'
        className={`siteNavigation__link ${item.primary ? 'siteNavigation__link--primary' : ''}`}
        onMouseEnter={() => {
          navigationStore.open(lowerCaseTitle)
        }}
      >
        {item.title}
      </a>
    )
  }
  // Override the default behavior for the dropdown nav links on mobile
  if (item.hasMobileDropdown && isMobile()) {
    // 1) No mouseEnter handler
    // 2) Toggle open status via onClick handler
    link = (
      <a
        onMouseEnter={undefined}
        onClick={() => {
          navigationStore.currentDropdown === lowerCaseTitle
            ? navigationStore.close()
            : navigationStore.open(lowerCaseTitle)
        }}
        className='siteNavigation__link'
      >
        {item.title === 'More' ? '+' : item.title}
      </a>
    )
  }
  return link
}

export default NavLink
