import React from 'react'
import FacebookIcon from 'icons/Facebook'
import TwitterIcon from 'icons/Twitter'
import InstagramIcon from 'icons/Instagram'
import YoutubeIcon from 'icons/Youtube'
import CCPALink from '../CCPALink'
import CaliforniaNoticesLink from '../CaliforniaNoticesLink'

const SiteFooter = () => {
  return (
    <nav className='siteFooter'>
      <div className='container'>
        <div className='siteFooter__bottom'>
          <img className='siteFooter__logo' src='/static/images/logo.png' alt='Barstool Bets - Barstool Gambling' />
          <ul className='siteFooter__socialLinks'>
            <li>
              <a href='https://www.facebook.com/stoolpresidente'>
                <FacebookIcon color='#000000' width='auto' height='20px' />
              </a>
            </li>
            <li>
              <a href='https://twitter.com/stoolpresidente'>
                <TwitterIcon color='#000000' width='auto' height='20px' />
              </a>
            </li>
            <li>
              <a href='https://www.instagram.com/stoolpresidente/'>
                <InstagramIcon color='#000000' width='auto' height='20px' />
              </a>
            </li>
            <li>
              <a href='https://www.youtube.com/channel/UC5PrkGgI_cIaSStOyRmLAKA'>
                <YoutubeIcon color='#000000' width='20px' height='auto' />
              </a>
            </li>
          </ul>
        </div>
        <p className='siteFooter__copyright'>© {new Date().getFullYear()} Barstool Sports. All rights reserved.</p>
        <p className='siteFooter__copyright'>
          <a href='https://www.barstoolsports.com/terms-of-use'>Terms of Use</a>
          <a href='https://www.barstoolsports.com/privacy-policy'>Privacy Policy</a>
          <a href='https://www.barstoolsports.com/content-policy'>Content Policy</a>
          <CCPALink
            style={{
              paddingRight: '12px',
              color: 'inherit'
            }}
          />
          <CaliforniaNoticesLink
            style={{
              paddingRight: '12px',
              color: 'inherit'
            }}
          />
        </p>
      </div>
      <style jsx>{`
        .col--section {
          min-width: 50%;
          flex-basis: 50%;
        }
        .siteFooter {
          background: #f9e5ad;
          padding: 30px 0 60px;
        }
        .siteFooter__listTitle {
          font-family: 'Bebas Neue';
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0.4px;
          line-height: 20px;
          margin: 0 0 12px 0;
        }
        .siteFooter__list {
          list-style: none;
          margin: 0 0 30px;
          padding: 0;
        }
        .siteFooter__listItem {
          font-size: 14px;
          letter-spacing: 0.1px;
          line-height: 20px;
          margin: 0 0 10px;
        }
        .siteFooter__bottom {
          padding: 20px 0;
        }
        .siteFooter__logo {
          display: block;
          width: auto;
          height: 22px;
          margin-bottom: 15px;
        }
        .siteFooter__socialLinks {
          display: flex;
          align-items: center;
          list-style: none;
          padding: 0;
          margin: 0;
        }
        .siteFooter__socialLinks li {
          margin: 0 12.5px;
          padding: 0;
        }
        .siteFooter__socialLinks li:first-child {
          margin-left: 0;
        }
        .siteFooter__copyright {
          font-size: 12px;
          letter-spacing: 0.1px;
          line-height: 15px;
        }
        .siteFooter__copyright a {
          padding-right: 12px;
          color: inherit;
        }
        @media (min-width: 769px) {
          .col--section {
            min-width: 16.66%;
            flex-basis: 16.66%;
          }
          .siteFooter {
            padding: 30px 0;
          }
          .siteFooter__bottom {
            display: flex;
            padding: 30px 0;
          }
          .siteFooter__logo {
            margin-bottom: 0;
          }
          .siteFooter__socialLinks {
            margin: 1px 0 1px 30px;
          }
          .siteFooter__socialLinks li:first-child {
            margin-left: 12.5px;
          }
        }
      `}</style>
    </nav>
  )
}

export default SiteFooter
