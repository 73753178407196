import React from 'react'
import { THEME } from 'utils'

const GlobalStyles = () => (
  <style jsx global>{`
    * {
      box-sizing: border-box;
    }
    /* Resets */
    input,
    label,
    select,
    button,
    textarea {
      -webkit-appearance: none;
      margin: 0 0 12px;
      border: 0;
      padding: 0;
      display: block;
      vertical-align: middle;
      white-space: normal;
      background: none;
      line-height: 1;
      width: 100%;
    }
    body {
      margin: 0;
      font-family: 'Helvetica Neue', Helvetica, BlinkMacSystemFont, Avenir Next, Avenir, -apple-system, sans-serif;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      color: #222;
      font-size: 16px;
    }
    body.drawer-open {
      overflow: hidden;
    }
    h1 {
      color: #222;
      font-size: 32px;
      font-weight: bold;
      letter-spacing: -0.1px;
      line-height: 40px;
    }
    p {
      font-size: 14px;
      letter-spacing: 0.1px;
      line-height: 20px;
      margin-top: 0;
    }
    a {
      text-decoration: none;
    }
    .text-center {
      text-align: center;
    }
    .finePrint {
      color: #757575;
      font-size: 10px;
      letter-spacing: 0.2px;
      line-height: 12px;
    }
    .finePrint a {
      font-weight: bold;
    }
    input,
    textarea,
    select {
      background-color: #f9f9f9;
      font-size: 14px;
      padding: 13.5px 12px;
    }
    textarea {
      border: 1px solid #d8d8d8;
      min-height: 85px;
    }
    select {
      height: 44px;
    }
    label {
      color: #222;
      font-size: 16px;
      font-weight: bold;
      line-height: 18px;
    }
    .form__error {
      background-color: #f8ece9;
      color: #d0341f;
      border: 1px solid #d0341f;
      text-align: center;
      font-size: 14px;
      letter-spacing: 0.1px;
      line-height: 20px;
      margin: 0 0 12px;
      padding: 16px;
    }
    .form__error--success {
      background-color: #eff8e9;
      color: #4c8c01;
      border-color: #5aa700;
    }
    .field__error {
      color: #d0341f;
      font-size: 10px;
      letter-spacing: 0.2px;
      line-height: 12px;
      margin: -8px 0 12px;
    }
    button[type='submit'],
    .btn {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.4px;
      line-height: 20px;
      padding: 12px 20px;
      text-align: center;
      cursor: pointer;
      transition: all 0.15s ease-in-out;
    }
    .btn--red {
      background: ${THEME.COLORS.PRIMARY};
      color: #fff;
    }
    .btn:disabled {
      cursor: not-allowed;
      background-color: #e8e8e8;
    }
    .btn.btn--iconRight,
    .btn.btn--iconLeft {
      display: inline-flex;
      align-items: center;
    }
    .btn--iconRight svg {
      margin-left: 16px;
    }
    .btn--primary {
      text-align: center;
      cursor: pointer;
      color: #000000;
      border: 1px solid #000000;
      background-color: #ffffff;
      box-shadow: 3px 3px 0 0 #000000;
    }
    .btn {
      display: inline-block;
      outline: none;
    }
    button[type='submit']:disabled {
      background: #e8e8e8;
      cursor: not-allowed;
    }
    hr {
      border: 0;
      border-top: 1px solid #d8d8d8;
    }
    .container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 15px;
    }
    .row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
    }
    .col {
      flex: 1;
      padding-left: 15px;
      padding-right: 15px;
    }
    .row--noGutters {
      margin-left: 0;
      margin-right: 0;
    }
    .row--noGutters .col {
      padding-left: 0;
      padding-right: 0;
    }
    .row--scroll {
      flex-wrap: nowrap !important;
      overflow-x: auto;
      padding-top: 16px;
    }
    .col--main {
      flex-basis: 100%;
      min-width: 100%;
    }
    .col--sidebar {
      flex-basis: 100%;
      min-width: 100%;
    }
    @media (min-width: ${THEME.BREAKPOINTS.SM}) {
      .col--main {
        min-width: 55%;
        flex-basis: 55%;
      }
      .col--sidebar {
        min-width: 45%;
        flex-basis: 45%;
      }
    }
    @media (min-width: ${THEME.BREAKPOINTS.MD}) {
      .col--main {
        min-width: 65%;
        flex-basis: 65%;
      }
      .col--sidebar {
        min-width: 35%;
        flex-basis: 35%;
      }
    }
    .container--form {
      max-width: 355px;
      padding-bottom: 100px;
    }
    /* react-select */
    .react-select__indicator-separator {
      display: none;
    }
    iframe {
      border: none;
    }
    /* Progress Bar */
    #nprogress {
      pointer-events: none;
    }
    #nprogress .bar {
      background: #c62232;
      position: fixed;
      z-index: 1031;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
    }
    #nprogress .peg {
      display: block;
      position: absolute;
      right: 0px;
      width: 100px;
      height: 100%;
      box-shadow: 0 0 10px #c62232, 0 0 5px #c62232;
      opacity: 1;
      -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
      transform: rotate(3deg) translate(0px, -4px);
    }
    .siteNavigation__link {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.2px;
      line-height: 19px;
      color: #222;
      text-decoration: none;
      padding: 0 12.5px;
      white-space: nowrap;
      height: 100%;
      display: flex;
      align-items: center;
    }
    .searchForm {
      display: flex;
    }
    .searchForm input {
      outline: none;
      border: 1px solid ${THEME.COLORS.LIGHTGRAY};
      border-right-width: 0;
      font-size: 16px;
    }
    .searchForm .btn {
      cursor: pointer;
      width: auto;
    }
    @media (min-width: 1024px) {
      .siteNavigation__link {
        padding: 0 25px;
      }
    }
    .siteNavigation__link--primary {
      font-size: 16px;
    }
    .siteNavigation__link:hover {
      color: #c62232;
    }
    .siteNavigation__dropdown--active .dropdownMenu {
      display: block;
    }
    .siteNavigation__dropdown--active .siteNavigation__link {
      color: #c62232;
    }
    .siteFooter__listItem a,
    .siteFooter__listItem a:visited {
      color: #222;
    }
    .sharingLink--sms {
      background-color: #33e223;
    }
    @media (min-width: ${THEME.BREAKPOINTS.LG}) {
      .siteNavigation__link {
        padding: 0 25px;
      }
      .siteNavigation__dropdown--more,
      .siteNavigation__dropdown--topics {
        position: relative;
      }
      .siteSubNavigation ul li a {
        margin-right: 40px;
      }
    }
    .dropdownMenu {
      display: none;
    }
    .layout__overlay--active {
      display: block !important;
    }

    /* Slick Slider */
    .slick-list,
    .slick-slider,
    .slick-track {
      position: relative;
      display: block;
    }
    .slick-loading .slick-slide,
    .slick-loading .slick-track {
      visibility: hidden;
    }
    .slick-slider {
      box-sizing: border-box;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -ms-touch-action: pan-y;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
    }
    .slick-list {
      overflow: hidden;
      margin: 0;
      padding: 0;
    }
    .productCarousel .slick-list {
      padding-top: 16px;
    }
    .slick-list:focus {
      outline: 0;
    }
    .slick-list.dragging {
      cursor: pointer;
      cursor: hand;
    }
    .slick-slider .slick-list,
    .slick-slider .slick-track {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    .slick-track {
      top: 0;
      left: 0;
    }
    .slick-track:after,
    .slick-track:before {
      display: table;
      content: '';
    }
    .slick-track:after {
      clear: both;
    }
    .slick-slide {
      display: none;
      float: left;
      height: 100%;
      min-height: 1px;
    }
    .slick-slide img {
      display: block;
    }
    .slick-slide.slick-loading img {
      display: none;
    }
    .slick-slide.dragging img {
      pointer-events: none;
    }
    .slick-initialized .slick-slide {
      display: block;
    }
    .slick-vertical .slick-slide {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
    .slick-arrow.slick-hidden {
      display: none;
    }
    .slick-dots,
    .slick-next,
    .slick-prev {
      position: absolute;
      display: block;
      padding: 0;
    }
    .slick-next,
    .slick-prev {
      font-size: 0;
      line-height: 0;
      top: 50%;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      cursor: pointer;
      color: transparent;
      border: none;
      outline: 0;
      background: 0 0;
    }
    .slick-next:focus,
    .slick-next:hover,
    .slick-prev:focus,
    .slick-prev:hover {
      color: transparent;
      outline: 0;
      background: 0 0;
    }
    .slick-prev {
      left: -25px;
    }
    .slick-prev:before {
      content: none;
    }
    .slick-next {
      right: -25px;
    }
    .slick-dotted.slick-slider {
      margin-bottom: 30px;
    }
    .slick-dots {
      bottom: -25px;
      width: 100%;
      margin: 0;
      list-style: none;
      text-align: center;
    }
    .slick-dots li {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
    }
    .slick-dots li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: 0;
      background: 0 0;
    }
    .slick-dots li button:focus,
    .slick-dots li button:hover {
      outline: 0;
    }
  `}</style>
)

export default GlobalStyles
