import { useEffect, useRef } from 'react'
import { isServer } from 'utils'

/**
 * stolen from https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * useInterval Hook accepts a function and a delay
 * the difference between the setInterval and useInterval Hook is that its arguments are “dynamic”
 * adjusting it dynamically can be useful — for example, to poll for some AJAX updates
 * less often while the user has switched to a different tab. the interval can also be paused by
 * updating the delay argument passed in as null
 *
 * it also properly encapsulates calling clearInterval when component unmounts, so there is
 * no need to worry about duplicating that same pattern everytime setInterval is used in a component
 */

/**
 *
 * @param {Function} callback
 * @param {number} delay
 */
export function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null && !isServer) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
