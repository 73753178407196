import React from 'react'
import { formatScore } from 'utils'

const RestaurantSchema = ({ restaurant, reviews }) => {
  const reviewsSchema = reviews.map((review) => {
    return {
      '@type': 'Review',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: `${Number(formatScore(review.score))}`,
        bestRating: '10',
        worstRating: '0'
      },
      author: {
        '@type': 'Person',
        name: `${review.user.firstName} ${review.user.lastName}`
      },
      itemReviewed: {
        '@type': 'Restaurant',
        name: `${restaurant.name}`,
        image: restaurant.imageUrl,
        servesCuisine: 'Pizza',
        priceRange: `${Array.apply(0, Array(restaurant.priceLevel))
          .map(() => '$')
          .join('')}`,
        address: {
          '@type': 'PostalAddress',
          addressLocality: `${restaurant.city}`,
          addressRegion: `${restaurant.state}`,
          streetAddress: `${restaurant.address1} ${restaurant.address2}`,
          postalCode: `${restaurant.zip}`,
          addressCountry: `${restaurant.country}`
        },
        telephone: `${restaurant.phoneNumber}`
      },
      datePublished: `${review.date}`,
      reviewBody: `${review.message || ''}`
    }
  })
  const restaurantSchema = {
    '@context': 'http://schema.org/',
    '@type': 'Restaurant',
    image: restaurant.imageUrl,
    name: `${restaurant.name}`,
    servesCuisine: 'Pizza',
    priceRange: `${Array.apply(0, Array(restaurant.priceLevel))
      .map(() => '$')
      .join('')}`,
    address: {
      '@type': 'PostalAddress',
      addressLocality: `${restaurant.city}`,
      addressRegion: `${restaurant.state}`,
      streetAddress: `${restaurant.address1} ${restaurant.address2}`,
      postalCode: `${restaurant.zip}`,
      addressCountry: `${restaurant.country}`
    },
    telephone: `${restaurant.phoneNumber}`,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingCount: `${restaurant.reviewStats.all.count}`,
      ratingValue: `${Number(formatScore(restaurant.reviewStats.all.averageScore))}`,
      bestRating: '10',
      worstRating: '0'
    },
    review: reviewsSchema
  }
  return (
    <script
      type='application/ld+json'
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(restaurantSchema)
      }}
    />
  )
}

export default RestaurantSchema
