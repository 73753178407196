import React from 'react'

export default function InstagramIcon({ color = '#222222', width = '18px', height = '18px' }) {
  return (
    <svg viewBox='0 0 33 33' className='svgIcon socialIcon iconInstagram' style={{ width, height, fill: color }}>
      <title>Instagram</title>
      <path d='M32.69,9.79a11.9,11.9,0,0,0-.76-4,7.65,7.65,0,0,0-1.87-2.9,8,8,0,0,0-2.88-1.88,11.93,11.93,0,0,0-4-.76c-1.74-.08-2.3-.1-6.72-.1h0c-4.42,0-5,0-6.72.1a11.9,11.9,0,0,0-4,.76A7.94,7.94,0,0,0,2.95,2.94,8,8,0,0,0,1.07,5.83a11.93,11.93,0,0,0-.76,4c-.08,1.74-.1,2.3-.1,6.72s0,5,.1,6.72a11.9,11.9,0,0,0,.76,4,8,8,0,0,0,1.88,2.89,8,8,0,0,0,2.88,1.88,11.93,11.93,0,0,0,4,.76c1.74.08,2.29.1,6.72.1s5,0,6.72-.1a11.9,11.9,0,0,0,4-.76,8.33,8.33,0,0,0,4.76-4.76,11.93,11.93,0,0,0,.76-4c.08-1.74.1-2.29.1-6.72S32.77,11.53,32.69,9.79ZM29.77,23.08a8.93,8.93,0,0,1-.56,3A5,5,0,0,1,28,28a5.11,5.11,0,0,1-1.87,1.22,9,9,0,0,1-3,.56c-1.72.08-2.23.1-6.58.1s-4.87,0-6.58-.1a8.93,8.93,0,0,1-3-.56A5,5,0,0,1,5,28a5.11,5.11,0,0,1-1.22-1.87,9,9,0,0,1-.56-3c-.08-1.72-.1-2.23-.1-6.58s0-4.87.1-6.58a8.93,8.93,0,0,1,.56-3A5.06,5.06,0,0,1,5,5,5.11,5.11,0,0,1,6.9,3.8a9,9,0,0,1,3-.56c1.71-.08,2.23-.1,6.58-.1h0c4.35,0,4.87,0,6.58.1a8.93,8.93,0,0,1,3,.56A5,5,0,0,1,28,5a5.11,5.11,0,0,1,1.22,1.87,9,9,0,0,1,.56,3c.08,1.72.1,2.23.1,6.58S29.85,21.36,29.77,23.08Z' />
      <path d='M16.51,8.13a8.37,8.37,0,1,0,8.37,8.37A8.37,8.37,0,0,0,16.51,8.13Zm0,13.8a5.43,5.43,0,1,1,5.43-5.43A5.43,5.43,0,0,1,16.51,21.93Z' />
      <circle cx='25.21' cy='7.8' r='1.95' />
    </svg>
  )
}
