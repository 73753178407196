import React from 'react'
import NextHead from 'next/head'
import { string } from 'prop-types'
import RestaurantSchema from 'components/RestaurantSchema'

const defaultDescription =
  'A list of the best pizza restaurants nearby whether you are in NYC, Chicago or anywhere else. Get the best pizza near you today and read & watch reviews for each pizza restaurant.'
const defaultOGURL = 'https://www.onebite.app'
const defaultOGImage = 'https://chumley.barstoolsports.com/wp-content/uploads/2019/11/14/onebite-share.jpg'

const Head = ({ scripts = [], noIndexNoFollow = false, restaurant = false, reviews = [], video = false, ...props }) => (
  <NextHead>
    <meta charSet='UTF-8' />
    <meta name='viewport' content='width=device-width, initial-scale=1' />
    <meta name='apple-itunes-app' content='app-id=1474632817' />
    <meta property='fb:app_id' content={process.env.FB_APP_ID} />
    <link rel='icon' sizes='192x192' href='/static/touch-icon.png' />
    <link rel='apple-touch-icon' href='/static/touch-icon.png' />
    <link rel='mask-icon' href='/static/favicon-mask.svg' color='#49B882' />
    <link href='/favicon.png' rel='shortcut icon' type='image/x-icon' />

    <meta name='twitter:app:name:iphone' content='One Bite' />
    <meta name='twitter:app:id:iphone' content='1356660515' />
    <meta name='twitter:app:name:ipad' content='One Bite' />
    <meta name='twitter:app:id:ipad' content='1356660515' />
    <meta name='twitter:site' content='@barstoolsports' />
    <meta name='twitter:card' content='summary_large_image' />

    <title>{props.title || 'One Bite App | Pizza Review App by Barstool Sports'}</title>
    <meta name='description' content={props.description || defaultDescription} />
    {props.url && <link rel='canonical' href={props.url} />}

    <meta property='og:url' content={props.url || defaultOGURL} />
    <meta property='og:title' content={props.title || ''} />
    <meta property='og:description' content={props.description || defaultDescription} />
    <meta property='og:image' content={props.ogImage || defaultOGImage} />
    <meta property='og:image:width' content='1200' />
    <meta property='og:image:height' content='630' />
    <meta property='og:type' content='website' />

    <meta name='twitter:site' content={props.url || defaultOGURL} />
    <meta name='twitter:card' content='summary_large_image' />
    <meta name='twitter:image' content={props.ogImage || defaultOGImage} />

    {restaurant && <RestaurantSchema restaurant={restaurant} reviews={reviews} />}
    {video && (
      <>
        <meta property='og:video' content={video} />
        <meta property='og:video:type' content='video/mp4' />
        <meta property='og:video:width' content='1280' />
        <meta property='og:video:height' content='720' />
      </>
    )}
    {noIndexNoFollow && <meta name='robots' content='noindex, nofollow' />}
    {scripts.map((props, index) => (
      <script key={index} {...props} async></script>
    ))}
  </NextHead>
)

Head.propTypes = {
  title: string,
  description: string,
  url: string,
  ogImage: string
}

export default Head
