import React, { useEffect, useState } from 'react'
import MediaQuery from 'react-responsive'
import VisibilitySensor from 'react-visibility-sensor'
import { AdSlot, DFPManager } from 'react-dfp'

function GoogleAd({ adUnit, sizes, uid, media = { minWidth: 0 } }) {
  const [adRendered, setAdRendered] = useState(false)
  const [onScreen, setOnScreen] = useState(false)
  useEffect(() => {
    if (!onScreen) return
    const op = setTimeout(() => {
      adRendered ? DFPManager.reload('ad') : DFPManager.load('ad')
    }, 100)
    return () => clearTimeout(op)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, onScreen])

  return (
    <MediaQuery {...media}>
      <VisibilitySensor partialVisibility scrollCheck onChange={(newValue) => setOnScreen(onScreen || newValue)}>
        <div style={{ minHeight: '1px' }}>
          <AdSlot
            slotId='ad'
            dfpNetworkId='57249056'
            sizes={sizes}
            adUnit={adUnit}
            onSlotRender={() => setAdRendered(true)}
          />
        </div>
      </VisibilitySensor>
    </MediaQuery>
  )
}

export default GoogleAd
