import React from 'react'
import { observer } from 'mobx-react'
import { getStore, THEME } from 'utils'
import { NavigationStore } from 'stores/navigation'
import NavLink from 'components/NavLink'

const SiteNavigation = () => {
  const navigationStore = getStore(NavigationStore)

  return (
    <nav className='siteNavigation'>
      <ul itemScope itemType='http://www.schema.org/SiteNavigationElement' className='siteNavigation__nav'>
        <div className='header'>MENU</div>
        {navigationStore.navigationItems.map((item, idx) => {
          return (
            <li key={idx} itemProp='name'>
              <NavLink item={item} />
            </li>
          )
        })}
      </ul>
      <style jsx>{`
        .siteNavigation {
          display: block;
        }
        .siteNavigation__nav {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: column;
          align-items: flex-end;
          overflow-x: auto;
          list-style: none;
          margin: 0;
          padding: 20px;
          height: 100%;
        }
        .siteNavigation__nav li {
          padding: 12px 0;
        }
        .header {
          font-size: 30px;
          padding: 0 12px 30px 0;
          font-weight: bold;
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}) {
          .header {
            display: none;
          }
          .siteNavigation {
            display: table;
            margin: auto;
          }
          .siteNavigation__nav {
            overflow-x: visible;
            flex-direction: row;
            align-items: flex-start;
            height: 100%;
            padding: 0;
          }
          .siteNavigation__nav li {
            height: 100%;
          }
        }
      `}</style>
    </nav>
  )
}

export default observer(SiteNavigation)
