import React from 'react'
import { observer } from 'mobx-react'
import GlobalStyles from 'components/GlobalStyles'
import SiteHeader from 'components/SiteHeader'
import SiteFooter from 'components/SiteFooter'
import GoogleAd from 'components/GoogleAd'
import { getStore, isServer } from 'utils'
import NavigationStore from 'stores/navigation'

const SiteLayout = ({ children, background = '#FFF', transparentHeader = false }) => {
  const navigationStore = getStore(NavigationStore)

  return (
    <>
      <div className='layout'>
        <SiteHeader transparentHeader={transparentHeader} />
        <div className='layout__overlayContainer' onClick={() => navigationStore.close()}>
          <div className='layout__overlay' />
          <main className='layout__main'>{children}</main>
          <SiteFooter />
          <div>
            <div className='ad ad--fixedBotom'>
              <GoogleAd
                sizes={[[320, 50]]}
                adUnit={`mWeb/One-Bite-Restaurant_Fixed-Bottom`}
                uid={isServer ? 'server' : window.location.pathname.replace(/\//g, '')}
                media={{ maxWidth: 1024 }}
              />
            </div>
          </div>
        </div>
        <GlobalStyles />
      </div>
      <style jsx>{`
        .layout {
          background: ${background};
          overflow-x: hidden;
        }
        .layout__main {
          min-height: calc(100vh - 324px);
        }
        .layout__overlayContainer {
          position: relative;
        }
        .layout__overlay {
          display: none;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 10;
          background: rgba(0, 0, 0, 0.5);
        }
        .ad--fixedBotom {
          text-align: center;
          background: rgba(255, 255, 255, 0.85);
          position: fixed;
          bottom: 0;
          width: 100%;
          z-index: 10;
        }
        @media (min-width: 769px) {
          .layout__main {
            min-height: calc(100vh - 280px);
          }
        }
      `}</style>
    </>
  )
}

export default observer(SiteLayout)
