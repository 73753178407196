import React from 'react'
import Link from 'next/link'
import { observer } from 'mobx-react'
import { useDialogState, Dialog, DialogBackdrop, DialogDisclosure } from 'reakit/Dialog'
import { Portal } from 'reakit/Portal'
import MediaQuery from 'react-responsive'
import { THEME } from 'utils'
import NavTriggerIcon from 'icons/NavTrigger'
import SiteNavigation from 'components/SiteNavigation'

const SiteHeader = ({ transparentHeader }) => {
  const dialog = useDialogState()
  return (
    <>
      <div>
        <Dialog {...dialog} tabIndex={0} aria-label='SiteNavigation'>
          <div className='mobileNavigation'>
            <SiteNavigation />
          </div>
        </Dialog>
        <Portal>
          <DialogBackdrop {...dialog}>
            <div className='mobileNavigationBackdrop' />
          </DialogBackdrop>
        </Portal>
      </div>
      <header className={`siteHeader ${transparentHeader ? 'siteHeader--transparent' : ''}`}>
        <div className='container'>
          <Link href='/'>
            <a className='siteHeader__logo'>
              <img src='/static/images/logo.png' alt='One Bite App | Pizza Review App by Barstool Sports' />
            </a>
          </Link>
          <div className='siteHeader__right'>
            <MediaQuery minWidth={960}>
              <SiteNavigation />
            </MediaQuery>
            <Link href='/download'>
              <a className='btn btn--primary'>Download</a>
            </Link>
            <MediaQuery maxWidth={959}>
              <DialogDisclosure {...dialog} style={{ marginBottom: 0, outline: 0 }}>
                <a className='navTrigger'>
                  <NavTriggerIcon width='auto' height='24px' />
                </a>
              </DialogDisclosure>
            </MediaQuery>
          </div>
        </div>
      </header>
      <style jsx>{`
        .siteHeader {
          background: ${THEME.COLORS.SECONDARY};
          position: relative;
        }
        .siteHeader--transparent {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          z-index: 5;
          background: transparent;
        }
        .siteHeader > .container {
          height: 80px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .siteHeader > .container .btn {
          padding: 6px 10px;
          font-size: 12px;
        }
        .siteHeader__logo {
          display: block;
        }
        .siteHeader__logo img {
          width: auto;
          height: 24px;
        }
        .siteHeader__right {
          display: flex;
          align-items: center;
        }
        .siteHeader__right .btn {
          margin-left: 25px;
        }
        .navTrigger {
          margin-left: 25px;
        }
        .mobileNavigation {
          position: fixed;
          top: 0;
          right: 0;
          background: #f9e5ad;
          height: 100vh;
          width: 66%;
          max-width: '250px';
          z-index: 6;
        }
        .mobileNavigationBackdrop {
          position: fixed;
          top: 0;
          left: 0;
          background: #000000;
          height: 100vh;
          width: 100%;
          opacity: 0.5;
          z-index: 5;
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}) {
          .siteHeader__logo img {
            height: 28px;
          }
          .siteHeader > .container .btn {
            padding: 12px 20px;
            font-size: 14px;
          }
        }
      `}</style>
    </>
  )
}

export default observer(SiteHeader)
