import React from 'react'

export default function NavTriggerIcon({ color = '#000', width = '18px', height = '18px' }) {
  return (
    <svg viewBox='0 0 20 18' className='svgIcon iconNavTrigger' style={{ width, height }}>
      <title>Open Navigation</title>
      <defs>
        <path
          id='nav-trigger-path'
          d='M20 15L20 17.5 0 17.5 0 15 20 15ZM20 7.5L20 10 0 10 0 7.5 20 7.5ZM20 0L20 2.5 0 2.5 0 0 20 0Z'
        />
      </defs>
      <g fill='none'>
        <g transform='translate(-330 -129)translate(0 94)translate(20 26)translate(308 6)translate(2 3)'>
          <mask id='nav-trigger-mask' fill='white'>
            <use xlinkHref='#nav-trigger-path' />
          </mask>
          <use xlinkHref='#nav-trigger-path' fill='#D8D8D8' />
          <g mask='url(#nav-trigger-mask)' fill={color}>
            <rect x='-41' y='-42' width='103' height='103' />
          </g>
        </g>
      </g>
    </svg>
  )
}
